class DropTarget extends HTMLElement {
  constructor() {
    super();
    const shadowRoot = this.attachShadow( { mode: 'open' });
    shadowRoot.innerHTML = '<slot></slot>';
  }

  connectedCallback() {
    if (!this.hasAttribute('over-class')) {
      this.setAttribute('over-class', 'over');
    }
    if (!this.hasAttribute('over-error-class')) {
      this.setAttribute('over-error-class', 'over-error');
    }
    if (!this.hasAttribute('drop-event')) {
      this.setAttribute('drop-event', 'dropped');
    }
    if (!this.hasAttribute('mime-types')) {
      this.setAttribute('mime-types', 'data,json');
    }

    this.addEventListener('dragenter', this.onDragEnter.bind(this));
    this.addEventListener('dragover', this.onDragOver.bind(this));
    this.addEventListener('dragleave', this.onDragLeave.bind(this));
    this.addEventListener('drop', this.onDrop.bind(this));
  }

  set overClass(value) {
    this.setAttribute('over-class', value);
  }

  get overClass() {
    return this.getAttribute('over-class');
  }

  set overErrorClass(value) {
    this.setAttribute('over-error-class', value);
  }

  get overErrorClass() {
    return this.getAttribute('over-error-class');
  }

  set expectedMimeTypes(value) {
    this.setAttribute('mime-types', value.join(','));
  }

  get expectedMimeTypes() {
    return this.getAttribute('mime-types').split(',');
  }

  set dropEventName(value) {
    this.setAttribute('drop-event', value);
  }

  get dropEventName() {
    return this.getAttribute('drop-event');
  }

  accepts(mimeTypes) {
    for (let validMime of this.expectedMimeTypes) {
      if (mimeTypes.includes(validMime)) {
        return true;
      }
    }
    return false;
  }

  onDragEnter(event) {
    this.classList.remove.apply(this.classList, [this.overClass, this.overErrorClass]);
    if (this.accepts(event.dataTransfer.types)) {
      event.preventDefault();
      this.classList.add(this.overClass);
    } else {
      this.classList.add(this.overErrorClass);
    }
  }

  onDragLeave() {
    this.classList.remove.apply(this.classList, [this.overClass, this.overErrorClass]);
  }

  onDragOver(event) {
    this.classList.remove.apply(this.classList, [this.overClass, this.overErrorClass]);
    if (this.accepts(event.dataTransfer.types)) {
      event.preventDefault();
      this.classList.add(this.overClass);
    } else {
      this.classList.add(this.overErrorClass);
    }
  }

  onDrop(event) {
    this.classList.remove.apply(this.classList, [this.overClass, this.overErrorClass]);
    if (this.accepts(event.dataTransfer.types)) {
      event.preventDefault();
      event.stopPropagation();

      let data = {};
      for (let mimeType of this.expectedMimeTypes) {
        if (event.dataTransfer.types.includes(mimeType)) {
          data[mimeType] = event.dataTransfer.getData(mimeType);
        }
      }

      this.raiseEvent(this.dropEventName, data, true, true);
    }
  }
}

customElements.define('drop-target', DropTarget);