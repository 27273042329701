import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["amountField", "unitCustField", "subtotalField"]

  initialize() {
    this.amountFieldTarget.addEventListener('input', this.calculateSubtotal.bind(this));
    this.unitCustFieldTarget.addEventListener('input', this.calculateSubtotal.bind(this));
  }
  
  calculateSubtotal() {
    let amountValue = parseFloat(this.amountFieldTarget.value);
    let unitCustValue = parseFloat(this.unitCustFieldTarget.value);
    if (amountValue === null) {
      return true;
    }
    this.subtotalFieldTarget.value = (amountValue * unitCustValue).toFixed(2)
  }
}
