import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = [ "driver", "driverFields", "supplier", "supplierFields"]


  initialize() {
    let checkboxDriver = document.getElementById('general_person_driver')
    let checkboxSupplier = document.getElementById('general_person_supplier')

    if(checkboxDriver.checked) {
      this.show_elements(this.driverFieldsTargets)
    }

    if(checkboxSupplier.checked){
      this.show_elements(this.supplierFieldsTargets);
    }

    checkboxDriver.addEventListener('change', e => {
      if(e.target.checked) {
        this.show_elements(this.driverFieldsTargets);
      } 
      else {
        this.hide_elements(this.driverFieldsTargets);
      }
    })

    checkboxSupplier.addEventListener('change', e => {
      if(e.target.checked) {
        this.show_elements(this.supplierFieldsTargets);
      } 
      else {
        this.hide_elements(this.supplierFieldsTargets);
      }
    })
  }

  show_elements(targets) {
    for (const element of targets){
      element.classList.remove("hidden")
    }
  }

  hide_elements(targets) {
    for (const element of targets){
      element.classList.add("hidden")
    }
  }
}