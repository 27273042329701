import {DataTable} from 'simple-datatables';
import { I18n } from 'i18n-js';

class Table extends HTMLElement {
  constructor() {
    super();

    I18n.locale = window.I18n.locale;
    I18n.translations = window.I18n.translations;
    this.data = JSON.parse(this.innerText);
    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `
<style>
  :host(*) {
    display: block;
  }
</style>
<slot></slot>
`;
  }

  i18nTable(){  
    var selectButton = document.querySelector('input[class="dataTable-input"]');
    var page = document.querySelector('select[class="dataTable-selector"]');
    selectButton.setAttribute('placeholder', I18n.t('table.search'));
    page.parentElement.lastChild.textContent = ` ${I18n.t('table.page')}`;
    var td = document.querySelector('td[class="dataTables-empty"]');
    if(td){
      td.parentElement.lastChild.textContent = ` ${I18n.t('table.no_entry_found')}`;
    }
  }

  connectedCallback(){
    const node = this.render();
    this.appendChild(node);
    this.i18nTable();
  }

  render(){
    this.innerText = '';

    var div = document.createElement('div');
    var table = document.createElement('table');
    div.appendChild(table);
    
    new DataTable(table,{
      data: this.data,
      layout: {
        top: '{select}{search}',
        bottom: '{pager}'
      }
    });


    
    return div;
  }

}

customElements.define('slg-table',Table);
