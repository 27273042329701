import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["amountField", "unitCostField", "totalField"]

  initialize() {
    this.amountFieldTarget.addEventListener('input', this.calculateTotal.bind(this));
    this.unitCostFieldTarget.addEventListener('input', this.calculateTotal.bind(this));
  }
  
  calculateTotal() {
    let amountValue = parseFloat(this.amountFieldTarget.value);
    let unitCostValue = parseFloat(this.unitCostFieldTarget.value);
    if (amountValue === null) {
      return true;
    }
    this.totalFieldTarget.value = (amountValue * unitCostValue).toFixed(2)
  }
}
