import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["order"];
  static values = { url: String };

  show() {
    let item = document.querySelector(`[data-id="${this.urlValue.slice(-1)}"][data-target="transport-order"]`);
    if (item) {
      item.tab.click();
    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          const wrapper = document.getElementById('travels');

          wrapper.appendChild(fragment);
        });
    }
  }


  hide() {
    if (this.hasOrderTarget) {
      this.orderTarget.classList.add("hidden");
    }
  }


  disconnect() {
    if (this.hasOrderTarget) {
      this.orderTarget.remove();
    }
  }
}