import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['target', 'plan', 'newRecord', 'nameFields', 'bankFields', 'agencyFields', 'accountFields', 'balanceDate', 'balanceFields', 'openingBalance', 'kindFields', 'dueDate', 'daysClosing', 'firstInvoice', 'financialAccount', 'cardLimit', 'invoiceBalance', 'supplierFields', 'planType', 'creditLimit', 'rechargeFields', 'financialPrepaid', 'minimumBalance', 'limitFields', 'financialFields', 'balance', 'limit', 'financial']

  initialize() {
    this.default_targets = [this.nameFieldsTarget, this.bankFieldsTarget, this.agencyFieldsTarget, this.accountFieldsTarget, this.dueDateTarget, this.daysClosingTarget, this.financialAccountTarget, this.cardLimitTarget, this.invoiceBalanceTarget, this.supplierFieldsTarget, this.planTypeTarget, this.creditLimitTarget, this.rechargeFieldsTarget, this.financialPrepaidTarget, this.minimumBalanceTarget, this.limitFieldsTarget, this.financialFieldsTarget, this.balanceDateTarget, this.balanceFieldsTarget, this.openingBalanceTarget, this.kindFieldsTarget, this.firstInvoiceTarget, this.minimumBalanceTarget, this.balanceTarget, this.limitTarget, this.financialTarget];

    this.current_account_targets = (this.hasNewRecordTarget) ? { enabled_targets: [this.nameFieldsTarget, this.bankFieldsTarget, this.agencyFieldsTarget, this.accountFieldsTarget, this.balanceDateTarget, this.balanceFieldsTarget, this.openingBalanceTarget, this.kindFieldsTarget, this.balanceTarget], disabled_targets: [this.dueDateTarget, this.daysClosingTarget, this.firstInvoiceTarget, this.invoiceBalanceTarget, this.supplierFieldsTarget, this.planTypeTarget, this.rechargeFieldsTarget, this.minimumBalanceTarget, this.limitTarget, this.financialTarget] } 
    : 
    { enabled_targets: [this.nameFieldsTarget, this.bankFieldsTarget, this.agencyFieldsTarget, this.accountFieldsTarget], disabled_targets: [this.dueDateTarget, this.daysClosingTarget, this.firstInvoiceTarget, this.supplierFieldsTarget, this.planTypeTarget, this.rechargeFieldsTarget, this.kindFieldsTarget, this.balanceDateTarget, this.balanceTarget, this.limitTarget, this.financialTarget] };

    this.cash_account_targets = (this.hasNewRecordTarget) ? { enabled_targets: [this.nameFieldsTarget, this.balanceDateTarget, this.balanceFieldsTarget, this.openingBalanceTarget, this.balanceTarget], disabled_targets: [this.bankFieldsTarget, this.agencyFieldsTarget, this.accountFieldsTarget, this.kindFieldsTarget, this.dueDateTarget, this.daysClosingTarget, this.firstInvoiceTarget, this.invoiceBalanceTarget, this.supplierFieldsTarget, this.planTypeTarget, this.rechargeFieldsTarget, this.minimumBalanceTarget, this.limitTarget, this.financialTarget] } 
    : 
    { enabled_targets: [this.nameFieldsTarget], disabled_targets: [this.bankFieldsTarget, this.agencyFieldsTarget, this.accountFieldsTarget, this.kindFieldsTarget, this.dueDateTarget, this.daysClosingTarget, this.firstInvoiceTarget, this.supplierFieldsTarget, this.planTypeTarget, this.rechargeFieldsTarget, this.balanceDateTarget, this.balanceTarget, this.limitTarget, this.financialTarget] };

    this.credit_card_targets = (this.hasNewRecordTarget) ? { enabled_targets: [this.nameFieldsTarget, this.balanceFieldsTarget, this.kindFieldsTarget, this.dueDateTarget, this.daysClosingTarget, this.firstInvoiceTarget, this.financialAccountTarget, this.financialFieldsTarget, this.cardLimitTarget, this.limitFieldsTarget, this.invoiceBalanceTarget, this.balanceTarget, this.limitTarget, this.financialTarget], disabled_targets: [this.bankFieldsTarget, this.agencyFieldsTarget, this.accountFieldsTarget, this.openingBalanceTarget, this.balanceDateTarget, this.supplierFieldsTarget, this.planTypeTarget, this.creditLimitTarget, this.rechargeFieldsTarget, this.financialPrepaidTarget, this.minimumBalanceTarget] } 
    : 
    { enabled_targets: [this.nameFieldsTarget, this.dueDateTarget, this.daysClosingTarget, this.financialAccountTarget, this.financialFieldsTarget, this.cardLimitTarget, this.limitFieldsTarget, this.limitTarget, this.financialTarget], disabled_targets: [this.bankFieldsTarget, this.agencyFieldsTarget, this.accountFieldsTarget, this.balanceDateTarget, this.supplierFieldsTarget, this.planTypeTarget, this.creditLimitTarget, this.rechargeFieldsTarget, this.financialPrepaidTarget, this.kindFieldsTarget, this.firstInvoiceTarget, this.balanceTarget] };

    this.automatic_payments_targets = { enabled_targets: [this.supplierFieldsTarget, this.planTypeTarget], disabled_targets: [this.nameFieldsTarget, this.bankFieldsTarget, this.agencyFieldsTarget, this.accountFieldsTarget, this.balanceDateTarget, this.balanceFieldsTarget, this.openingBalanceTarget, this.kindFieldsTarget, this.dueDateTarget, this.daysClosingTarget, this.firstInvoiceTarget, this.financialAccountTarget, this.cardLimitTarget, this.invoiceBalanceTarget, this.financialPrepaidTarget, this.minimumBalanceTarget, this.limitFieldsTarget, this.financialFieldsTarget, this.creditLimitTarget, this.rechargeFieldsTarget] }

    this.postpaid_targets = { enabled_targets: [this.supplierFieldsTarget, this.planTypeTarget, this.kindFieldsTarget, this.dueDateTarget, this.daysClosingTarget, this.firstInvoiceTarget, this.financialAccountTarget, this.financialFieldsTarget, this.creditLimitTarget, this.limitFieldsTarget, this.balanceFieldsTarget, this.invoiceBalanceTarget, this.balanceTarget, this.limitTarget, this.financialTarget], disabled_targets: [this.openingBalanceTarget, this.cardLimitTarget, this.rechargeFieldsTarget, this.financialPrepaidTarget, this.minimumBalanceTarget] };

    this.prepaid_targets = { enabled_targets: [this.supplierFieldsTarget, this.planTypeTarget, this.balanceFieldsTarget, this.minimumBalanceTarget, this.rechargeFieldsTarget, this.financialPrepaidTarget, this.financialFieldsTarget, this.balanceTarget, this.financialTarget], disabled_targets: [this.openingBalanceTarget, this.kindFieldsTarget, this.dueDateTarget, this.daysClosingTarget, this.firstInvoiceTarget, this.financialAccountTarget, this.invoiceBalanceTarget, this.creditLimitTarget, this.limitFieldsTarget, this.limitTarget] };

    this.redraw_inputs(this.targetTarget.value);
  }

  change_inputs(event) {
    this.redraw_inputs(event.target.value);
  }

  redraw_inputs(value) {
    this.hide_elements(this.default_targets);

    switch(value) {
      case 'current_account':
        this.show_elements(this.current_account_targets['enabled_targets']);
        this.hide_elements(this.current_account_targets['disabled_targets']);
        break;
      case 'cash_account':
        this.show_elements(this.cash_account_targets['enabled_targets']);
        this.hide_elements(this.cash_account_targets['disabled_targets']);
        break;
      case 'credit_card':
        this.show_elements(this.credit_card_targets['enabled_targets']);
        this.hide_elements(this.credit_card_targets['disabled_targets']);
        break;
      case 'automatic_payments':
        this.show_elements(this.automatic_payments_targets['enabled_targets']);
        this.hide_elements(this.automatic_payments_targets['disabled_targets']);

        this.redraw_inputs(this.planTarget.value);
        break;
      case 'postpaid':
        this.show_elements(this.postpaid_targets['enabled_targets']);
        this.hide_elements(this.postpaid_targets['disabled_targets']);
        break;
      case 'prepaid':
        this.show_elements(this.prepaid_targets['enabled_targets']);
        this.hide_elements(this.prepaid_targets['disabled_targets']);
        break;
      default:
        this.hide_elements(this.default_targets);
    }
  }

  show_elements(elements){
    for (var element of elements) {
      element.classList.remove('hidden');
    }
  }

  hide_elements(elements){
    for (var element of elements) {
      element.classList.add('hidden');
      this.disable_inputs(element.children)
    }
  }

  disable_inputs(input_list){
    for(const input of input_list){
      if(input instanceof HTMLInputElement) input.removeAttribute('required');
    }
  }
}