import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [ "completeChild", "noneChild", "child" ]

    checkAll() {
        this.completeChildTargets.map(x => x.checked = true)
        this.noneChildTargets.map(x => x.checked = false)
        this.checkAllChilds();
    }

    uncheckAll() {
        this.completeChildTargets.map(x => x.checked = false)
        this.noneChildTargets.map(x => x.checked = true)
        this.uncheckAllChilds();
    }

    checkAllChilds() {
        this.childTargets.forEach(child => child.checked = true);
    }

    uncheckAllChilds() {
        this.childTargets.forEach(child => child.checked = false);
    }
}
