
import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['root', 'sun', 'moon'];

    initialize() {
        const theme = this.getTheme();
        this.rootTarget.classList.add(theme);
        this.changeInput(theme);
    }

    onClick(event){
        if(this.getTheme() === 'dark'){
          this.setTo('light', 'dark')
        }else {
          this.setTo('dark', 'light')
        }
    }

    setTo(theme, from){
        this.setTheme(theme)
        this.rootTarget.classList.add(theme)
        this.rootTarget.classList.remove(from)
        this.changeInput(theme)
    }

    changeInput(theme){
      if(theme === 'dark') {
        this.sunTarget.classList.add('hidden')
        this.moonTarget.classList.remove('hidden')
      } else {
        this.sunTarget.classList.remove('hidden')
        this.moonTarget.classList.add('hidden')
      }
    }


    setTheme(theme) {
        localStorage.setItem('color-theme', theme)
    }

    getTheme() {
        return localStorage.getItem('color-theme')
    }

}