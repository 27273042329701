import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["click"]

    initialize() {
        this.collaboratorField = document.querySelector('input[id="user_collaborator_id"][type="hidden"]');
        if (this.hasClickTarget) this.collaboratorField.addEventListener('change', this.click.bind(this));
    }

    click() {
        this.clickTarget.href = this.buildURL(this.clickTarget, this.collaboratorField.value)
        this.clickTarget.click()
    }

    buildURL(target, id) {
        const url = new URL(target.href, window.location.href)
        const params = new URLSearchParams(url.search.slice(1).replace(/&collaborator_id=(\d+$)/, ''))
        params.append("collaborator_id", id)
        url.search = params.toString()

        return url.toString()
    }
}