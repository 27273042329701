import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["parcel", "parcelFields"]

  initialize() {
    let selectRepetition = document.getElementById('expense_repetition')

    selectRepetition.addEventListener('change', e => {
      if(e.target.value == "unique") {
        this.hide_elements(this.parcelFieldsTargets);
      } 
      else {
        this.show_elements(this.parcelFieldsTargets);
      }

      let valueElement = document.getElementById('expense_value');
      let parcelNumberElement = document.getElementById('expense_parcel_number');

      if (selectRepetition) {
          parcelNumberElement.addEventListener('keyup', this.calculateParcelValue.bind(this));
      }
    })

    let expenseValueField = document.getElementById('expense_value')
    expenseValueField.addEventListener('keyup', () => {
      let transactionValue = document.getElementById('expense_expanded_transactions_attributes_0_transaction_value')
      transactionValue.value = expenseValueField.value
    })
  }

  show_elements(targets) {
    for (const element of targets){
      element.classList.remove("hidden")
    }
  }

  hide_elements(targets) {
    for (const element of targets){
      element.classList.add("hidden")
    }
  }

  calculateParcelValue() {
    let total_value = parseFloat(document.getElementById('expense_value').value);
    let parcel_number = parseInt(document.getElementById('expense_parcel_number').value);

    if (isNaN(parcel_number)) {
      parcel_number = 1;
    }

    let total_parcel = (total_value / parcel_number).toFixed(2);

    document.getElementById('expense_parcel_value').value = parseFloat(total_parcel)
  }
}
