import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-fetch-response", function (event) {
      if (typeof (event.detail.fetchResponse) !== 'undefined') {
        var response = event.detail.fetchResponse.response
        if (response.redirected) {
          event.preventDefault()
          Turbo.visit(response.url)
        }
      }
    });
  }
}