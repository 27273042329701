import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['total', 'amount', 'unit', 'startLoad', 'finishLoad', 'duration', 'valueHelper', 'optionSelect']
  static values = { url: String }

  connect() {
    this.resourceSelectField = this.optionSelectTarget
    this.refreshValueHelpers(this.resourceSelectField)
  }

  calculate() {
    if (this.amountTarget.value != "" && this.unitTarget.value != ""){
      let unit = parseFloat(this.unitTarget.value);
      let amount = parseFloat(this.amountTarget.value);

      this.totalTarget.value = amount * unit
      return
    } else {
      this.totalTarget.value = null
    }
  }

  loadDuration() {
    if (this.startLoadTarget.value != "" && this.finishLoadTarget.value != ""){
      let startDate = new Date(this.startLoadTarget.value);
      let finishDate = new Date(this.finishLoadTarget.value);
      let diferencaMinutos = ((finishDate - startDate) / (1000 * 60));

      this.durationTarget.value = diferencaMinutos
      return
    } else {
      this.durationTarget.value = null
    }
  }

  refreshValueHelpers() {
    const resource = this.resourceSelectField.value

    this.valueHelperTargets.forEach(valueHelper => {
      valueHelper.src = this.buildURL(resource)
    });
  }

  buildURL(id){
    const url = new URL(this.urlValue);
    url.searchParams.set('resource_id', id);
    return url.toString();
  }
}