import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "select" , "fields" ]

 connect() {
    this.sortable = Sortable.create(this.element, {
        onEnd: this.end.bind(this),
        onStart: this.start.bind(this),
        onMove: this.move.bind(this)
    })
 } 

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()

    data.append('position', event.newIndex + 1)

    Rails.ajax({
      url: this.data.get('url').replace(':id', id),
      type: 'PATCH',
      data: data
    })

    Array.from(event.target.children).forEach((element) => {
        if(!element.classList.contains('hover:bg-gray-300')){
            element.classList.add('hover:bg-gray-300')
          } 
    })
    console.log(event)
  }

  start (event) {
    console.log(event)  
  }

  move (event) {
    event.related.classList.remove('hover:bg-gray-300')
    console.log(event)
  }
}