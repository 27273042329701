import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["click"]

  initialize() {
    this.documentField = document.getElementById('general_person_document_number');
    this.documentField.addEventListener('input', this.click.bind(this));
  }

  click() {
    this.documentField.href = this.buildURL(this.documentField.value)
  }

  buildURL(number) {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search.slice(1).replace(/&document=(\d+$)/, ''))
    params.append("document", number)
    url.search = params.toString()

    return url.toString()
  }
}