import { Controller } from '@hotwired/stimulus';
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
      'period',
      'monthlyValue',
      'plan',
      'planValue',
      'addon',
      'addonPrice',
      'extra',
      'extraPrice',
      'signButton',
      'checkoutPlans',
      'checkoutExtras',
      'checkoutAddons',
      'checkoutPlanRow',
      'checkoutExtraRow',
      'checkoutAddonRow',
      'checkoutMonthlyValue',
      'checkoutPlanSelected',
      'checkoutPlanSelectedValue',
      'checkoutPlanSelectedPeriod',
      'checkoutPlanSelectedValueDiscount',
      'submitButton',
      'invoiceItemsForm'
  ]
  static values = {
      checkoutUrl: String,
      extrasUrl: String,
  }

  connect() {
    this.updateMonthlyValue()
    this.enableButton()
    if (!this.hasSelectedPlan()) return

    this.renderExtras();
    this.renderForm();
  }

  updateMonthlyValue() {
    if (!this.hasSelectedPlan()) return
    let monthly_value = this.calculate_monthly_value()

    this.monthlyValueTarget.innerHTML = this.price_format(monthly_value)
  }

	calculate_monthly_value() {
    let plans_sum_prices = this.planTargets.filter((planTarget) => planTarget.checked == true).reduce((sum, plan) => sum + parseFloat(plan.dataset.price), 0)
    let addon_sum_prices = this.addonTargets.filter((addonTarget) => addonTarget.checked == true).reduce((sum, addon) => sum + parseFloat(addon.dataset.price), 0)
    let extras_sum_price = this.extraTargets.reduce((sum, extra) => sum + this.extraPrice(extra), 0)
    
    let period = this.active_period()
    let time = parseInt(period.dataset.time)
    let discount = parseFloat(period.dataset.discount)

    let total_value = ((plans_sum_prices + extras_sum_price + addon_sum_prices) * time)
    let discount_value = (total_value * discount)
    let monthly_value = (total_value - discount_value ) / time

    return monthly_value
  }

  updateValue(){
    this.updateMonthlyValue()
  }

  updatePrice(event) {
    let target = event.target
    let quantity = ( target.value - target.min)
    let price_value = parseFloat(target.dataset.price)
    const extra_price = this.extraPriceTargets.find(extraPrice => extraPrice.dataset.id == target.dataset.target)
    extra_price.innerHTML = this.price_format(( price_value * quantity))
    this.updateMonthlyValue()
  }

  extraPrice(target) {
    let quantity = ( target.value - target.min)
    let price_value = parseFloat(target.dataset.price)
    return price_value * quantity
  }

  active_period() {
    let period = this.periodTargets.find( period => period.checked === true)
    return period
  }

  price_format(value) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
    return formatter.format(value)
  }

  enableButton() {
    if (!this.hasSelectedPlan()) return
    if (this.signButtonTarget.disabled) this.signButtonTarget.removeAttribute('disabled')
  }

  renderCheckoutData() {
    let monthly_value = this.calculate_monthly_value()
    let period = this.active_period()
    let time = parseInt(period.dataset.time)
    this.checkoutMonthlyValueTarget.innerHTML = this.price_format(monthly_value * time)

    this.cleanCheckoutData()


    this.render_plans()
    this.render_discount()
    this.renderCheckoutExtras()
    this.renderCheckoutAddons()
    this.renderCheckoutFormData()
  }

  renderExtras() {
    let selectedPlans = this.selectedPlans()
    if (!selectedPlans) return

    let plan_ids = selectedPlans.map((plan) => parseInt(plan.dataset.planId))
    let body = new FormData()

    plan_ids.forEach((plan_id) => body.append('plan_ids[]', plan_id))

    post(`${this.extrasUrlValue}`, {
      body: body,
      responseKind: "turbo-stream"
  })

  }

  renderForm() {
    let selectedPlans = this.selectedPlans()
    if (!selectedPlans) return

    let period = this.active_period()
    let plan_ids = selectedPlans.map((plan) => parseInt(plan.dataset.planId))
    let body = new FormData()

    plan_ids.forEach((plan_id) => body.append('plan_ids[]', plan_id))
    body.append('contracted_period', period.dataset.time)
    body.append('discount_percentage', parseFloat(period.dataset.discount))

    post(`${this.checkoutUrlValue}`, {
        body: body,
        responseKind: "turbo-stream"
    })
  }

  renderCheckoutFormData() {
    this.submitButtonTarget.setAttribute('form', `new_invoice`)

    let form_index = 0;

    if (!this.hasExtraTarget) return

    const validAddonTargets = this.addonTargets.filter((addonTarget) => addonTarget.checked == true);
    const validExtraTargets = this.extraTargets.filter((extraTarget) => extraTarget.value !== extraTarget.min);

    validAddonTargets.forEach((addonTarget) => {
      this.renderInvoiceItem({addonTarget: addonTarget, form_index: form_index});
      form_index++
    });
    
    validExtraTargets.forEach((extraTarget) => {
      this.renderInvoiceItem({extraTarget: extraTarget, form_index: form_index});
      form_index++
    });
  }


  renderInvoiceItem({addonTarget = null, extraTarget= null, form_index = 0}) {
    let name = document.createElement('input')
    name.setAttribute('autocomplete', 'off');
    name.setAttribute('type', 'hidden');
    name.setAttribute('name', `invoice[invoice_items_attributes][${form_index}][name]`);
    name.setAttribute('id', `invoice_invoice_items_attributes_${form_index}_name`);

    let quantity = document.createElement('input')
    let planItem = document.createElement('input')
    let addon = document.createElement('input')


    if(addonTarget !== null){
      addon.setAttribute('autocomplete', 'off');
      addon.setAttribute('type', 'hidden');
      addon.setAttribute('name', `invoice[invoice_items_attributes][${form_index}][addon_id]`);
      addon.setAttribute('id', `invoice_invoice_items_attributes_${form_index}_addon_id`);
      name.value = addonTarget.dataset.label
      addon.value = addonTarget.dataset.id

      this.invoiceItemsFormTarget.append(name, addon)
    }

    if(extraTarget !== null){
      quantity.setAttribute('autocomplete', 'off');
      quantity.setAttribute('type', 'hidden');
      quantity.setAttribute('name', `invoice[invoice_items_attributes][${form_index}][quantity]`);
      quantity.setAttribute('id', `invoice_invoice_items_attributes_${form_index}_quantity`);

      planItem.setAttribute('autocomplete', 'off');
      planItem.setAttribute('type', 'hidden');
      planItem.setAttribute('name', `invoice[invoice_items_attributes][${form_index}][plan_item_id]`);
      planItem.setAttribute('id', `invoice_invoice_items_attributes_${form_index}_plan_item_id`);
      name.value = extraTarget.dataset.name
      quantity.value = extraTarget.value - parseInt(extraTarget.getAttribute('min'))
      planItem.value = extraTarget.dataset.id

      this.invoiceItemsFormTarget.append(name, quantity, planItem)
    }
  }

  // TODO: O render e igual para todos, o ideal e extrair as partes e tornar menos repetitivo @Daniel 16/06/24

  render_plans() {
    if (!this.hasPlanTarget) return false

    this.planTargets.map(( plan ) => {
      if (plan.checked != true) return false

      let row = document.createElement('tr')
      row.setAttribute('data-billing-target', 'checkoutPlanRow')
      row.setAttribute('class', 'capitalize bg-white border-b dark:bg-gray-800 dark:border-gray-700')

      let nameLine = document.createElement('td')
      nameLine.innerHTML = plan.getAttribute('data-label')
      nameLine.setAttribute('class', 'px-4 py-3' )
      row.appendChild(nameLine)

      let valueLine = document.createElement('td')
      valueLine.innerHTML = this.price_format(plan.getAttribute('data-price'))
      valueLine.setAttribute('class', 'px-4 py-3' )
      row.appendChild(valueLine)

      this.checkoutPlansTarget.insertAdjacentElement("afterend", row)
    });
  }

  render_discount() {
    let period = this.active_period();
    this.checkoutPlanSelectedValueDiscountTarget.innerHTML = `${parseFloat(period.dataset.discount) * 100}%`
  }

  renderCheckoutAddons() {
    if (!this.hasAddonTarget) return false

    this.addonTargets.map((addon) => {
      if (addon.checked != true) return false
      
      let row = document.createElement('tr')
      row.setAttribute('data-billing-target', 'checkoutAddonRow')
      row.setAttribute('class', 'capitalize bg-white border-b dark:bg-gray-800 dark:border-gray-700')

      let nameLine = document.createElement('td')
      nameLine.innerHTML = addon.getAttribute('data-label')
      nameLine.setAttribute('class', 'px-4 py-3' )
      row.appendChild(nameLine);

      let valueLine = document.createElement('td')
      valueLine.innerHTML = this.price_format(this.extraPrice(addon))
      valueLine.setAttribute('class', 'px-4 py-3' )
      row.appendChild(valueLine)

      this.checkoutAddonsTarget.insertAdjacentElement("afterend", row)
    })
  }

  renderCheckoutExtras() {
    if (!this.hasExtraTarget) return false

    this.extraTargets.map((extraTarget) => {
      if (extraTarget.value === extraTarget.min) return false

      let row = document.createElement('tr')
      row.setAttribute('data-billing-target', 'checkoutExtraRow')
      row.setAttribute('class', 'capitalize bg-white border-b dark:bg-gray-800 dark:border-gray-700')

      let nameLine = document.createElement('td')
      nameLine.innerHTML = `${extraTarget.getAttribute('data-name')} (${extraTarget.value - extraTarget.getAttribute('min')}) `
      nameLine.setAttribute('class', 'px-4 py-3' )
      row.appendChild(nameLine);

      let valueLine = document.createElement('td')
      valueLine.innerHTML = this.price_format(this.extraPrice(extraTarget))
      valueLine.setAttribute('class', 'px-4 py-3' )
      row.appendChild(valueLine)

      this.checkoutExtrasTarget.appendChild(row)
    })
    }


    hasSelectedPlan() {
      if (!this.hasPlanTarget) return false

			if (this.planTargets.some(x => x.checked)) return true
			
			return false
    }

    selectedPlans() {
			if (!this.hasPlanTarget) return false

			return this.planTargets.filter((planTarget) => planTarget.checked == true);
    }

    cleanCheckoutData() {
      this.checkoutPlanRowTargets.forEach((checkoutExtraRow) => {
        checkoutExtraRow.remove()
      })

      this.checkoutExtraRowTargets.forEach((checkoutExtraRow) => {
        checkoutExtraRow.remove()
      })

      this.checkoutAddonRowTargets.forEach((row) => {
        row.remove()
      })
    }
}
