import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ["additionalFields"]

  initialize() {
    let checkboxAdditional = document.getElementById('additional_plan_metric')

    if(checkboxAdditional.checked) {
      this.show_elements(this.additionalFieldsTargets)
    }

    checkboxAdditional.addEventListener('change', e => {
      if(e.target.checked) {
        this.show_elements(this.additionalFieldsTargets);
      } 
      else {
        this.hide_elements(this.additionalFieldsTargets);
      }
    })
  }

  show_elements(targets) {
    for (const element of targets){
      element.classList.remove("hidden")
    }
  }

  hide_elements(targets) {
    for (const element of targets){
      element.classList.add("hidden")
    }
  }
}
