import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["click"]

    initialize() {
        this.infractionIdHiddenField = document.querySelector('input[id="traffic_ticket_infraction_id"][type="hidden"]');
        this.infractionIdHiddenField.addEventListener('change', this.click.bind(this));
    }

    click() {
        this.clickTargets.forEach(target => {
            target.href = this.buildURL(target, this.infractionIdHiddenField.value)
            target.click()
        })
    }

    buildURL(target, id) {
        const url = new URL(target.href, window.location.href)
        const params = new URLSearchParams(url.search.slice(1).replace(/&infraction_id=(\d+$)/, ''))
        params.append("infraction_id", id)
        url.search = params.toString()

        return url.toString()
    }
}