
import { Controller } from 'stimulus';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

var clusters = new Map();
var map = null;

export default class extends Controller {
  static targets = ['item', 'map', 'pool']

  connect() {

    if (this.hasMapTarget) {
      map = new google.maps.Map(this.mapTarget, {
        zoom: 4,
      });
      map.setCenter({ lat: -14, lng: -55 });
    }

    if (this.hasPoolTarget) {
      this.poolTargets.forEach((target) => {
        let cluster = JSON.parse(target.dataset.content);
        const markers = cluster.map((location) => {
          return new google.maps.Marker({
            position: location,
            map: map,
          })
        });

        clusters.set(target.dataset.id, new MarkerClusterer({ map: map, markers }));
      })
    }
  }

  focus(event) {
    let target = event.target;
    let cluster = clusters.get(target.dataset.id);

    map.setCenter(cluster.clusters[0].position);
    map.setZoom(12);
  }
}