import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['valueHelper', 'optionSelect']
  static values = { url: String }

  connect() {
    this.fuelKindSelectField = this.optionSelectTarget
    this.refreshValueHelpers(this.fuelKindSelectField)
  }

  refreshValueHelpers() {
    const fuelKindId = this.fuelKindSelectField.value

    this.valueHelperTargets.forEach(valueHelper => {
      valueHelper.src = this.buildURL(fuelKindId)
    });
  }

  buildURL(id) {
    const url = new URL(this.urlValue);
    url.searchParams.set('fuel_kind_id', id);
    return url.toString();
  }
}