class HelpButton extends HTMLElement {
  constructor() {
    super();
  }

  get hidden() {
    return !this.classList.contains('shown');
  }

  set hidden(value) {
    value ? this.classList.remove('shown') : this.classList.add('shown');
  }

  toggle() {
    this.hidden = !this.hidden;
  }

  connectedCallback() {
    this.setAttribute('text', this.innerText);
    this.innerText = 'help';
    this.addEventListener('click', this.toggle.bind(this));
  }
}

customElements.define('help-button', HelpButton);