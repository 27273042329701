import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["productsFrame", "main", "subtotal", "discount", "shipping", "tax", "total", "itemAmount", "itemUnitCost", "itemValue", "totalItemValue"]

  initialize() {
    this.updateSubtotal();
  }

  updateSubtotal() {
    let subtotalHiddenField = document.querySelector('input[id="purchase_order_subtotal"]');

    let total = this.totalItemValueTargets.reduce((x, y) => {
      return x + parseFloat(y.getAttribute("data-purchase-order-value")) 
    }, 0)
    
    this.subtotalTarget.textContent = total.toFixed(2)
    subtotalHiddenField.value = total;

    this.updateTribute();
    this.updateTotal(total);
  }

  updateTotal(subtotal) {
    let totalHiddenField = document.querySelector('input[id="purchase_order_total"]');

    let discountValue = document.querySelector('input[id="purchase_order_discount"]').value;
    let shippingValue = document.querySelector('input[id="purchase_order_shipping"]').value;
    let taxValue = document.querySelector('input[id="purchase_order_tax"]').value;

    let taxType = document.querySelector('select[id="purchase_order_tax_type"]').value;
    let discountType = document.querySelector('select[id="purchase_order_discount_type"]').value;

    let totalTax = taxType === 'fix_tax' ? taxValue : (subtotal * taxValue) / 100;
    let totalDiscount = discountType === 'fix_discount' ? discountValue : (subtotal * discountValue) / 100;

    let total = (Number(subtotal) - Number(totalDiscount)) + (Number(shippingValue) + Number(totalTax));

    this.totalTarget.textContent = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
    totalHiddenField.value = total;
    console.log(total);
  }

  updateTribute() {
    let discountValue = document.querySelector('input[id="purchase_order_discount"]').value;
    let shippingValue = document.querySelector('input[id="purchase_order_shipping"]').value;
    let taxValue = document.querySelector('input[id="purchase_order_tax"]').value;

    this.discountTarget.textContent = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(discountValue || 0);
    this.shippingTarget.textContent = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(shippingValue || 0);
    this.taxTarget.textContent = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(taxValue || 0);
  }
}
