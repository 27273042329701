import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["none", "some", "parent", "child"]

    connect() {
        this.updateCheckboxes();
    }

    updateCheckboxes() {
        const checkedCount = this.childTargets.filter(child => child.checked).length;
        this.parentTarget.checked = checkedCount === this.childTargets.length;
        this.noneTarget.checked = checkedCount === 0;
        this.someTarget.checked = checkedCount > 0 && checkedCount < this.childTargets.length;
    }

    toggleChildren() {
        if (this.noneTarget.checked) {
            this.childTargets.forEach(child => child.checked = false);
        } else {
            const allChecked = this.parentTarget.checked;
            this.childTargets.forEach(child => child.checked = allChecked);
        }
        this.updateCheckboxes();
    }

    toggleParent() {
        this.parentTarget.checked = !this.childTargets.some(child => !child.checked);
        this.updateCheckboxes();
    }
}