class Page extends HTMLElement {
  get caption() {
    return this.getAttribute('caption');
  }

  set caption(value) {
    this.setAttribute('caption', value);
  }

  get active() {
    return this.hasAttribute('active');
  }

  set active(value) {
    if (value) {
      this.setAttribute('active', '');
      this.raiseEvent('show', this, true, true, true);
    } else {
      this.removeAttribute('active');
    }
  }

  connectedCallback() {
    this.parentElement.registerPage(this);
  }
}

class StaticPage extends Page {
  constructor() {
    super();

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `
<style>
  :host(*) {
    display: none;
  }
  :host([active]) {
    display: inline-block;
    width: 100%;
  }
</style>
<slot></slot>
`;
  }
}

class AsyncPage extends Page {
  constructor() {
    super();

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `
<style>
  :host(*) {
    display: none;
  }
  :host([active]) {
    display: inline-grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas: "content";
  }
  iframe {
    z-index: 1;
    visibility: hidden;
    grid-area: content;
    border: 0;
  }
  div#loading {
    z-index: 2;
    grid-area: content;
    display: inline-grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    align-items: center;
    justify-items: center;
  }
</style>
<div id="loading">
  <img src="/images/loading.gif" />
</div>
<iframe id="frame" src="javascript:void(0);"></iframe>
`;
    this.frame = this.shadowRoot.getElementById('frame');
    this.loading = this.shadowRoot.getElementById('loading');
  }

  get src() {
    return this.getAttribute('src');
  }

  onShow() {
    if (!this.shown) {
      this.frame.addEventListener('load', this._hideLoading.bind(this));
      this.frame.src = this.src;
      this.shown = true;
    }
  }

  _hideLoading() {
    this.loading.style.display = 'none';
    this.frame.style.visibility = 'visible';
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('show', this.onShow.bind(this));
    if (this.active) {
      this.raiseEvent('show', this, true, true, true);
    }
  }
}

class PageControl extends HTMLElement {
  constructor() {
    super();

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `
<style>
  * {
    box-sizing: border-box;
  }

  :host(*) {
    display: inline-grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content 1fr;
    grid-template-areas: "tabs" "content";
    border: var(--border-color, #cccccc) 1px solid;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  nav {
    grid-area: tabs;
    border-bottom: var(--border-color, #cccccc) 1px solid;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
  }
  nav > button {
    margin: 0 0 0 0;
    padding: 1rem 2rem .5rem 2rem;
    background: transparent;
    border: none;
    border-bottom: var(--border-color, #cccccc) 4px solid;
    font-size: 1em;
  }
  nav > button.active {
    background-color: #f5f5f5;
    user-select: none;
    font-weight: bold;
    border-bottom: 4px solid  #2196f3 ;
    color: #2196f3;
  }
  
  nav > button:focus {
    outline: none;
  }
  ::-moz-focus-inner {
    border: 0;
  }
  :-moz-focusring {
    outline: 0;
  }
  
  #content {
    width: 100%;
    height: 100%;
    grid-area: content;
    overflow-y: auto !important;
  }
</style>
<nav></nav>
<div id="content"><slot></slot></div>
`;
  }

  set activeTab(tab) {
    if (this._activeTab) {
      this._activeTab.classList.remove('active');
    }
    this._activeTab = tab;
    this._activeTab.classList.add('active');
  }

  get activeTab() {
    return this._activeTab;
  }

  set activePage(tab) {
    if (this._activePage) {
      this._activePage.active = false;
    }
    this._activePage = tab;
    this._activePage.active = true;
  }

  get activePage() {
    return this._activePage;
  }

  connectedCallback() {
    this.tabContainer = this.shadowRoot.querySelector('nav');
    this.tabContainer.addEventListener('click', this.onTabClick.bind(this));
  }

  registerPage(page) {
    let tab = this.tabContainer.appendNewChild('button', {}, page.caption);
    page.tab = tab;
    tab.page = page;
    if (page.active) {
      this.activePage = page;
      this.activeTab = tab;
    }
  }

  onTabClick(event) {
    let button = event.target;
    if (button.tagName.toUpperCase() !== 'BUTTON') {
      return;
    }

    this.activeTab = button;
    this.activePage = button.page;
  }
}
customElements.define('page-control', PageControl);
customElements.define('static-page', StaticPage);
customElements.define('async-page', AsyncPage);