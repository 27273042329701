import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["workforce", "product", "workforceCount", "productCount", "optionCategory", "optionSystem", "optionCluster", "tagFieldSystem", "tagFieldCluster", "tagFieldComponent"]
  static values = { url: String }

  initialize() {
    this.countWorkforces()
    this.countProducts()
  }

  workforceTargetConnected() {
    this.countWorkforces();
  }

  productTargetConnected() {
    this.countProducts();
  }

  workforceTargetDisconnected() {
    this.countWorkforces();
  }

  productTargetDisconnected() {
    this.countProducts();
  }

  countWorkforces() {
    if (this.hasWorkforceCountTarget) this.workforceCountTarget.textContent = `(${this.workforceTargets.length})`
  }

  countProducts() {
    if (this.hasProductCountTarget) this.productCountTarget.textContent = `(${this.productTargets.length})`
  }

  refreshField(targets, id, paramName) {
    targets.forEach(target => target.src = this.buildURL(id, paramName));
  }

  buildURL(id, paramName) {
    const url = new URL(this.urlValue);
    url.searchParams.set(paramName, id);
    return url.toString();
  }

  refreshCategory() {
    this.refreshField(this.tagFieldSystemTargets, this.optionCategoryTarget.value, 'category_id');

    if (!this.optionCategoryTarget.value) {
      this.clearFields([this.tagFieldSystemTargets, this.tagFieldClusterTargets, this.tagFieldComponentTargets]);
    }
  }

  refreshSystem() {
    this.refreshField(this.tagFieldClusterTargets, this.optionSystemTarget.value, 'system_id');

    if (!this.optionSystemTarget.value) {
      this.clearFields([this.tagFieldClusterTargets, this.tagFieldComponentTargets]);
    }
  }

  refreshCluster = () => this.refreshField(this.tagFieldComponentTargets, this.optionClusterTarget.value, 'cluster_id');

  clearFields(targetGroups) {
    targetGroups.forEach(group => this.refreshField(group, null, null));
  }
}
