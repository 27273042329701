import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["workHourField", "hourlyRateField", "subtotalField"]

  initialize() {
    this.workHourFieldTarget.addEventListener('input', this.calculateSubtotal.bind(this));
    this.hourlyRateFieldTarget.addEventListener('input', this.calculateSubtotal.bind(this));
  }
  
  calculateSubtotal() {
    let workHourValue = parseFloat(this.workHourFieldTarget.value);
    let hourlyRateValue = parseFloat(this.hourlyRateFieldTarget.value);
    if (workHourValue === null) {
      return true;
    }
    this.subtotalFieldTarget.value = (workHourValue * hourlyRateValue).toFixed(2);
  }
}
