import { Controller } from 'stimulus';


export default class extends Controller {
    static targets = ["button", "addButton", "removeButton", "additionalUsageMeterFrame", "divForm"]

    hideAddButton() {
        this.addButtonTarget.classList.add("hidden")
        this.removeButtonTarget.classList.remove("hidden")

    }

    hideAdditionalUsageMeter() {
        this.addButtonTarget.classList.remove("hidden")
        this.removeButtonTarget.classList.add("hidden")
        this.additionalUsageMeterFrameTarget.remove()
        this.createFrame()
    }

    createFrame() {
        let frame = document.createElement('turbo-frame')
        frame.setAttribute('id', "new-secondary-usage-meter")
        frame.setAttribute('class', "col-span-6")
        frame.setAttribute('data-usage-meter-target', "additionalUsageMeterFrame")
        frame.setAttribute('target', "configuration-usage-meters-frame")
        this.divFormTarget.appendChild(frame)
    }
}