import { Controller } from 'stimulus';



export default class extends Controller {
  static targets = ['target', 'host', 'port', 'secure', 'user', 'password', 'key', 'enabled']

  initialize() {
    this.default_targets = [this.hostTarget, this.portTarget, this.secureTarget, this.userTarget, this.passwordTarget, this.keyTarget, this.enabledTarget];
    this.stc_targets = { enabled_targets: [this.hostTarget, this.portTarget, this.secureTarget, this.userTarget, this.passwordTarget, this.keyTarget, this.enabledTarget], disabled_targets: [] };
    this.cta_targets = { enabled_targets: [this.keyTarget], disabled_targets: [this.hostTarget, this.portTarget, this.secureTarget, this.userTarget, this.passwordTarget, this.enabledTarget] };
    this.routech_targets = { enabled_targets: [this.userTarget, this.passwordTarget], disabled_targets: [this.hostTarget, this.portTarget, this.keyTarget, this.secureTarget, this.enabledTarget]}
    this.concept_targets = { enabled_targets: [this.userTarget, this.passwordTarget], disabled_targets: [this.hostTarget, this.portTarget, this.keyTarget, this.secureTarget, this.enabledTarget]}
    this.redraw_inputs(this.targetTarget.value);
  }


  change_inputs(event) {
    this.redraw_inputs(event.target.value);
  }

  redraw_inputs = (value) => {
    switch(value) {
      case 'stc':
        this.show_elements(this.stc_targets['enabled_targets']);
        this.hide_elements(this.stc_targets['disabled_targets']);
        break;
      case 'cta':
        this.show_elements(this.cta_targets['enabled_targets']);
        this.hide_elements(this.cta_targets['disabled_targets']);
        break;
      case 'routech':
        this.show_elements(this.routech_targets['enabled_targets']);
        this.hide_elements(this.routech_targets['disabled_targets']);
        break;
      case 'concept':
        this.show_elements(this.concept_targets['enabled_targets']);
        this.hide_elements(this.concept_targets['disabled_targets']);
        break;
      default:
        this.hide_elements(this.default_targets);
    }
  }

  show_elements(elements){
    for (var element of elements) {
      element.classList.remove('hidden');
      this.enable_inputs(element.children)
    }
  }

  hide_elements(elements){
    for (var element of elements) {
      element.classList.add('hidden');
      this.disable_inputs(element.children)
    }
  }

  disable_inputs(input_list){
    for(const input of input_list){
      if(input instanceof HTMLInputElement) input.removeAttribute('required');
    }
  }

  enable_inputs(input_list){
    for(const input of input_list){
      if(input instanceof HTMLInputElement) input.setAttribute('required', 'required');;
    }
  }
}