import { create, cssomSheet } from 'twind';

const sheet = cssomSheet({ target: new CSSStyleSheet() });
const { tw } = create({ sheet });

class ModalDialog extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({ mode: 'open' });
    shadow.adoptedStyleSheets = [sheet.target];
    shadow.innerHTML = `
<style>
  * {
    box- sizing: border - box;
  }
  :host {
  position: absolute;
  display: none;
  flex - flow: column nowrap;
  align - items: center;
  justify - content: center;
  left: 0;
  top: 0;
  width: 100 %;
  height: 100 %;
  z - index: 200;
  background: rgba(128, 128, 128, 0.2);
}
  iframe {
  width: 100 %;
  height: 100 %;
  border: none;
}
</style >
  <div id="dialog" class="${tw`fixed z-10 inset-0 overflow-y-auto`}" >
    <div id="header" class="${tw`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}">
      <div class="${tw`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity`}" aria-hidden="true">
        <span class="${tw`hidden sm:inline-block sm:align-middle sm:h-screen`}" aria-hidden="true">&#8203;</span>
        <div class="${tw`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}">
          <div class="${tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}">
            <div class="${tw`sm:flex sm:items-start`}">
              <div class="${tw`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10`}">
                <svg class="${tw`h-6 w-6 text-red-600`}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="${tw`mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left`}">
                <h3 class="${tw`text-lg leading-6 font-medium font-bold text-gray-900`}" id="title">
                </h3>
                <div class="${tw`mt-2`}">
                  <p id="content" class="${tw`text-sm font-bold	text-gray-500`}">
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="footer" class="${tw`bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse`}">
            <slot name="left"></slot>
            <slot name="right"></slot>
          </div>
        </div>
      </div>
      `;
  }

  connectedCallback() {
    this._title = this.shadowRoot.getElementById('title');
    this._content = this.shadowRoot.getElementById('content');
    this._buttons = new Map([
      ['left', this.shadowRoot.getElementById('left')],
      ['center', this.shadowRoot.getElementById('center')],
      ['right', this.shadowRoot.getElementById('right')]
    ]);
    this._footer = this.shadowRoot.getElementById('footer');
    this._footer.addEventListener('click', this.onButtonClick.bind(this));
    this._title.innerHTML = this.caption;
    this._content.innerHTML = this.content;
  }

  get content() {
    return this.getAttribute('content');
  }

  get caption() {
    return this.getAttribute('caption');
  }

  set caption(value) {
    this.setAttribute('caption', value);
    this._title.innerHTML = value;
  }

  set content(value) {
    this.setAttribute('content', value);
    this._content.innerHTML = value;
  }

  set buttons(options) {
    ['left', 'center', 'right'].forEach((position) => { this._buttons.get(position).innerHTML = ''; });
    for (let button of options) {
      let newButton = document.createElement('button');
      newButton.innerHTML = button.caption;
      newButton.dataset.data = button.data;
      newButton.dataset.resolve = button.resolve;
      newButton.className = 'standard-button';
      this._buttons.get(button.position).appendChild(newButton);
    }
  }

  onButtonClick(event) {
    let button = event.target;
    if (button.tagName.toLowerCase() !== 'button') {
      return;
    }

    this.close(button.dataset.resolve === 'true', button.dataset.data);
  }

  show(options = {}) {
    return new Promise((resolve, reject) => {
      if (options.hideCloseButton) {
        this.closeButton = false;
      }
      if (options.width) {
        this._content.style.width = options.width;
      }
      if (options.height) {
        this._content.style.height = options.height;
      }
      this.resolve = resolve;
      this.reject = reject;
      this.style.display = 'inline-flex';
    });
  }

  dialogBox(caption, text, buttons, options = {}) {
    this.caption = caption;
    this._content.innerHTML = `<p>${text}</p>`;
    this.buttons = buttons;
    return this.show(options);
  }

  messageBox(caption, text, options = {}) {
    return this.dialogBox(caption, text, [{ caption: '✔', data: 'ok', resolve: true, position: 'center' }], options);
  }

  confirmationBox(caption, text, options = {}) {
    return this.dialogBox(caption, text, [
      { caption: '✔', data: 'yes', resolve: true, position: 'left' },
      { caption: '❌', data: 'no', resolve: true, position: 'right' }
    ], options);
  }

  close(resolve, data = '') {
    this.style.display = 'none';
    if (resolve) {
      this.resolve(data);
    } else {
      this.reject(data);
    }
  }
}
customElements.define('modal-dialog', ModalDialog);