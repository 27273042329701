import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["multiFactorField", "multiplicationFactorField", "totalField", "click"]

    initialize() {
        this.multiFactorFieldTarget.addEventListener('input', this.calculateTotal.bind(this));
        this.multiFactorFieldTarget.addEventListener('change', this.calculateTotal.bind(this));
        this.multiplicationFactorFieldTarget.addEventListener('input', this.calculateTotal.bind(this));

        this.infractionCategoryIdHiddenField = document.querySelector('input[id="infraction_infraction_category_id"][type="hidden"]');
        this.infractionCategoryIdHiddenField.addEventListener('change', this.click.bind(this));

        this.multiFactorFieldTarget.addEventListener('change', this.addReadOnlyTotalField.bind(this));
        this.totalFieldTarget.addEventListener('input', this.updateInfractionValueField.bind(this));

        this.infractionCategory = document.getElementById('infraction_infraction_category_id');
        this.infractionCategory.addEventListener('change', this.addReadOnlyMultiFactor.bind(this));
    }

    click() {
        this.clickTargets.forEach(target => {
            target.href = this.buildURL(target, this.infractionCategoryIdHiddenField.value)
            target.click()
        })
    }

    calculateTotal() {
        if (this.infractionCategoryIdHiddenField.value === '') return true;

        let regex = /[^0-9.-]+/g

        let multiFactorValue = this.multiFactorFieldTarget.value.replace(regex, "");
        let multiplicationFactorValue = this.multiplicationFactorFieldTarget.value.replace('R$', '').replace(regex, "");

        let totalValue = Number(multiFactorValue) * (Number(multiplicationFactorValue) / 100);

        if (multiFactorValue === null || multiplicationFactorValue === null || isNaN(totalValue)) return true;

        this.totalFieldTarget.value = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValue)
    }

    buildURL(target, id) {
        const url = new URL(target.href, window.location.href)
        const params = new URLSearchParams(url.search.slice(1).replace(/&infraction_category_id=(\d+$)/, ''))
        params.append("infraction_category_id", id)
        url.search = params.toString()

        return url.toString()
    }

    addReadOnlyTotalField() {
        if (this.multiFactorFieldTarget.value == 0) {
            this.totalFieldTarget.readOnly = false;
        } else {
            this.totalFieldTarget.readOnly = true;
        }
    }

    addReadOnlyMultiFactor() {
        if (this.infractionCategory.value) {
            this.multiFactorFieldTarget.readOnly = false;
            this.totalFieldTarget.readOnly = true;
        }
    }

    updateInfractionValueField() {
        document.getElementById('infraction_infraction_value').value = this.totalFieldTarget.value;
    }
}
