import { Loader } from "@googlemaps/js-api-loader"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { apiKey: String, 
                    typeMap: { type: String, default: 'pin' },
                    mode: { type: String, default: 'none' },
                    lat: Number, lng: Number };

  static targets = ['map', 'lat', 'lng', 'search', 'coords', 'shape', 'pull', 'color_picker', 'button'];
  editMode = null;
  markers = [];
  shape = null;
  // path = new google.maps.MVCArray();

  initialize() {
    this._loader = new Loader({
      apiKey: this.apiKeyValue,
    });

    this._gMaps = this._loader.importLibrary('maps');
    this._gMarker = this._loader.importLibrary('marker');

    switch(this.typeMapValue) {
      case 'pin':
        this.initPinMap();
        break;
      case 'fence':
        this.initFenceMap();
        break;
    }
  }

  initPinMap() {
    this._gMaps.then(({Map}) => {
      if (this.hasMapTarget) {
        if (this.latValue && this.lngValue && this.modeValue == 'none') {
          this._map = new Map(this.mapTarget, { zoom: 10, center: { lat: this.latValue, lng: this.lngValue } });
          
          this._gMarker.then(({Marker}) => {
            this._marker = new Marker({ position: {  lat: this.latValue, lng: this.lngValue }, map: this._map, editable: false, visible: true });
          });
        } else {
          if (this.modeValue == 'edit') {
            this._map = new Map(this.mapTarget, { zoom: 6, center: { lat: this.latValue, lng: this.lngValue } });
            
            this._gMarker.then(({Marker}) => {
              this._marker = new Marker({ position: { lat: this.latValue, lng: this.lngValue }, map: this._map, editable: true, visible: true });
            });
            this._map.addListener('click', this.onMapClick.bind(this));
          } else  {
            this._map = new Map(this.mapTarget, { zoom: 4, center: { lat: -14, lng: -55 } });
            this._map.addListener('click', this.onMapClick.bind(this));
          }
        }
      }
    });
  }

  initFenceMap() {
    this.editMode = /edit|new/.test(document.location.href);

    if (this.hasMapTarget) {
      this.map = new google.maps.Map(this.mapTarget, {
        zoom: 12,
      });
    }

    if (this.hasSearchTarget) {
      this._searchTarget = new google.maps.places.SearchBox(this.searchTarget);
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchTarget);

      this.map.addListener("bounds_changed", () => {
        this._searchTarget.setBounds(this.map.getBounds());
      });

      this._searchTarget.addListener("places_changed", () => {
        let places = this._searchTarget.getPlaces();
        if (places.length == 0) return;
        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          const bounds = new google.maps.LatLngBounds();

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          };
          this.map.fitBounds(bounds);
        });
      })
    }

    if (this.editMode) {
      this.shape = new google.maps.Polygon({
        clickable: true,
        editable: true,
        paths: [],
        strokeColor: this.color_pickerTarget.value,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: this.color_pickerTarget.value,
        fillOpacity: 0.35,
        draggable: true,
      });
      this.map.addListener('click', this.onMapClick.bind(this));

      google.maps.event.addListener(this.shape, 'drag', () => this.syncShapeText());
      google.maps.event.addListener(this.path, 'insert_at', () => this.syncShapeText());
      google.maps.event.addListener(this.path, 'set_at', () => this.syncShapeText());

    } else {
      this.shape = new google.maps.Polygon({
        clickable: true,
        paths: [],
        strokeColor: this.mapTarget.dataset.color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: this.mapTarget.dataset.color,
        fillOpacity: 0.35,
      });
    }

    this.shape.setPath(this.path);
    this.shape.setMap(this.map);

    let coords = document.getElementById('coords');

    if (coords) {
      let xyCoords = JSON.parse(coords.content);

      for (let latlng of xyCoords[0]) {
        this.addPoint(new google.maps.LatLng(latlng[0], latlng[1]));
      }
    }

    if (this.hasPullTarget) {
      this.pullTargets.forEach((target) => {
        let cluster = JSON.parse(target.dataset.content);
        this.markers = cluster.map((location) => {
          return new google.maps.Marker({
            position: location,
            map: this.map,
            draggable: true,
            visible: false
          })
        });
        new MarkerClusterer({ map: this.map, markers: this.markers });
      })
    }

    if (!this.hasCoordsTarget) {
      if (this.mapTarget.dataset.lat && this.mapTarget.dataset.lat) {
        let mapLatLng = { lat: parseFloat(this.mapTarget.dataset.lat), lng: parseFloat(this.mapTarget.dataset.lng) };
        this.map.setCenter(mapLatLng)
        new google.maps.Marker({ position: mapLatLng, map: this.map });
        this.map.setZoom(10);
      } else {  
        this.map.setCenter({ lat: -14, lng: -55 });
        this.map.setZoom(4);
      }
    }
  }

  onMapClick(event) {
    this.addPoint(event.latLng);
  }

  addPoint(latLng) {
    if(this.typeMapValue == 'pin'){
      this._loader.importLibrary('marker').then(({Marker}) => {
        if(this._marker) this._marker.setMap(null);

        this._marker = new Marker({ position: latLng, map: this._map, editable: true, visible: true });

        this.latTarget.value = latLng.lat();
        this.lngTarget.value = latLng.lng();
      });
      return;
    }


    this.path.push(latLng);

    let marker = new google.maps.Marker({ position: latLng, map: this.map, draggable: true, editable: true, visible: false });
    this.markers.push(marker);

    if (this.editMode) {
      this.syncShapeText();
    }
  }

  syncShapeText() {
    let coords = [];

    for (var i = 0; i < this.path.length; i++) {
      var point = {
        lat: this.path.getAt(i).lat(),
        lng: this.path.getAt(i).lng()
      };
      coords.push(point);
    }
    this.shapeTarget.value = JSON.stringify(coords);
  }

  onPlacesChanged() {
    let places = this.mapSearchTarget.getPlaces();
    let bounds = new google.maps.LatLngBounds();
    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    this.mapTarget.fitBounds(bounds);
  }

  changeColor() {
    let color = this.color_pickerTarget.value;
    this.shape.setOptions({ strokeColor: color, fillColor: color })
  }

  reset() {
    for (let marker of markers) {
      marker.setMap(null);
    }
    markers = [];
    shape.setPath([]);
  } 
}