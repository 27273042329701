import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ['zipcode', 'address', 'city', 'district', 'uf']

  fill({ target }) {
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'default'
      };

    const zipcode = target.value.replace('-', '') 

    if(zipcode.length < 8 ) {
      if(this.hasCityTarget){
        this.enable(this.cityTarget)
      }
      if(this.hasAddressTarget){
        this.enable(this.addressTarget)
      }
      if(this.hasDistrictTarget) {
        this.enable(this.districtTarget)
      }
      if(this.hasUfTarget){
        this.enable(this.ufTarget)
      }
      return
    }

    fetch(`https://viacep.com.br/ws/${zipcode}/json/`, options)
      .then(res => {
        res.json()
          .then( data => {
            if(this.hasCityTarget){
              this.cityTarget.value = data.localidade
              this.disable(this.cityTarget)
            }
            if(this.hasAddressTarget){
              this.addressTarget.value = data.logradouro
              this.disable(this.addressTarget)
            }
            if(this.hasDistrictTarget) {
              this.districtTarget.value = data.bairro
              this.disable(this.districtTarget)
            }
            if(this.hasUfTarget) {
              this.ufTarget.value = data.uf
              this.disable(this.ufTarget)

            }
          });
      });
    
  }

  disable(element) {
    element.setAttribute('readOnly', true)
  }

  enable(element) {
    element.removeAttribute('readOnly')
  }
}