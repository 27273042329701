import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['resource', 'meterHelper']
  static values = { url: String }

  connect() {
    this.resourceHiddenField = this.resourceTarget.nextElementSibling
    this.resourceHiddenField.addEventListener('change', this.refreshMeterValues.bind(this))

    if (this.resourceHiddenField.value !== null) this.refreshMeterValues()
  }

  refreshMeterValues() {
    const resourceId = this.resourceHiddenField.value

    this.meterHelperTargets.forEach(target => {
      target.src = this.buildURL(resourceId)
    });
  }

  buildURL(id) {
    const url = new URL(this.urlValue);
    url.searchParams.set('resource_id', id);
    return url.toString();
  }
}

