import { Controller } from "stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
    static targets = ["notification"]
    static values = { url: String }


    click() {
        let element = this.notificationTarget
        patch(element.dataset.notificationUrl, {
            responseKind: "turbo-frame"
        });
    }
}