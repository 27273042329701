import { Controller } from 'stimulus';
import IMask from 'imask';

export default class extends Controller {
  static targets = [ "hidden" ]
  masks = []
  
  submit() {
    for(const mask of this.masks) {
      mask.el.value = mask.unmaskedValue
    }
  }

  connect(){
    const elements = Array.from(this.element.elements)
    for(const element of elements) {
      if(element.dataset.mask !== undefined ){
        this.maskField(element)
      }
    }
  }

  maskField(field) {
    if (field.dataset.mask === "currency") {
      this.masks.push(IMask(field, {
        mask: "R$num",
        blocks: {
          num: {
            mask: Number,
            signed: true,
            padFractionalZeros: true,
            normalizeZeros: false,
            radix: ',',
            thousandsSeparator: '.'
          }
        }
      }))
    } else {
      const fieldMasks = field.dataset.mask.split(',')
      const maskObject = fieldMasks.map((elem) => { return { mask: elem }  })

      this.masks.push(IMask(field, { mask: maskObject }))
    }
  }

  toogle(){
    event.preventDefault()
    const hiddenTarget = this.hiddenTargets[event.currentTarget.dataset.id]
    const hidden = hiddenTarget.classList.contains('hidden')
    hiddenTarget.classList.toggle('hidden', !hidden)
  }

  disconnect(){
    for(const mask of this.masks) {
      mask?.destroy()
    }
  }
}
