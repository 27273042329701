import React from "react";
import ReactDOM from "react-dom";
import { Gantt } from "gantt-task-react";
import { ViewMode } from "gantt-task-react";
import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails"

const currentDate = new Date();

export default class extends Controller {
  static targets = [ "gantt", "vehicle", "assignee" ]

  initialize() {
    if (!this.data.get("initialized")) {
      this.initTasks();
    }
  }

  initTasks(tasks = []) {
    if (tasks.length == 0) {
      if (this.hasVehicleTarget) {
        this.vehicleTargets.forEach(project => {
          var task = {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDay()),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDay()),
            name: project.getAttribute('data-operator-scales-name-value'),
            id: project.getAttribute('data-operator-scales-name-value'),
            progress: 100,
            isDisabled: true,
            styles: {
              backgroundColor: '#FFFFFF'
            },
            type: "project",
            hideChildren: false,
          }
    
          tasks.push(task);

          if (this.hasAssigneeTarget) {
            this.assigneeTargets.forEach(element => {
              if (element.getAttribute('data-operator-scales-project-value') === project.getAttribute('data-operator-scales-name-value')) {
                var startDate = new Date(element.getAttribute('data-operator-scales-start-date-value'))
                var endDate = new Date(element.getAttribute('data-operator-scales-end-date-value'))
                var percent = Math.round((currentDate - startDate) / (endDate - startDate) * 100)

                var task = {
                  start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
                  end: new Date(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()).setHours(23, 59, 59, 999)),
                  name: element.getAttribute('data-operator-scales-person-value'),
                  type: "task",
                  progress: (percent > 100) ? 100 : (percent < 0) ? 0 : percent,
                  id: element.getAttribute('data-operator-scales-id-value'),
                  project: element.getAttribute('data-operator-scales-project-value'),
                }

                if (currentDate.getTime() >= task.start.getTime() && currentDate.getTime() <= task.end.getTime()) {
                  task.styles = { backgroundColor: "#4040ff", progressColor: "#2f2fbe" }
                } else {
                  task.styles = { backgroundColor: "#ffa500", progressColor: "#bd7b00" }
                }

                if (task.end.getTime() >= currentDate.getTime()) tasks.push(task);
              }
            });
          }
        });
      }
    }

    this.tasks = tasks;
    this.initReactComponent();
  }

  initReactComponent() {
    const handleExpanderClick = (task) => {
      this.initTasks(this.tasks.map(t => (t.id === task.id ? task : t)));
    };

    const handleClick = (task) => {
      Turbo.visit(this.buildURL(task.id));
    }

    const viewMode = ViewMode.Day;

    const postProcess = this.debounce(() => this.removeTitles());

    ReactDOM.render(
      <Gantt
        tasks={this.tasks}
        viewMode={viewMode}
        locale={"pt-BR"}
        onExpanderClick={handleExpanderClick}
        onClick={handleClick}
        viewDate={currentDate}
      />,
      this.ganttTarget
    );

    this.data.set("initialized", true);
    postProcess();
  }

  buildURL(id) {
    const url = new URL(`${this.ganttTarget.getAttribute('src')}/${id}`, window.location.href)

    return url.toString()
  }

  debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  removeTitles() {
    document.querySelectorAll('._WuQ0f')[0].innerHTML = `&nbsp; ${this.ganttTarget.getAttribute('data-resource-human-attribute-name')}`;
    document.querySelectorAll('._WuQ0f')[1].innerHTML = `&nbsp; ${this.ganttTarget.getAttribute('data-expected-start-date-human-attribute-name')}`;
    document.querySelectorAll('._WuQ0f')[2].innerHTML = `&nbsp; ${this.ganttTarget.getAttribute('data-expected-end-date-human-attribute-name')}`;
    document.querySelectorAll('._3KcaM').forEach(e => e.remove());
    document.querySelectorAll('._1KJ6x').forEach(e => e.remove());
  }
}
