import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "frame"]

  initialize() {
    let resourceHiddenInput = document.querySelector('input[id="travel_occurrence_travel_event_id"][type="hidden"]');

    resourceHiddenInput.addEventListener('input', () => {
      this.updateSrc(resourceHiddenInput.value);      
    });
  }

  updateSrc(resource_id) {
    this.frameTarget.setAttribute('src', this.buildURL(resource_id));
  } 

  buildURL(resource_id) {
    const url = new URL(this.frameTarget.src, window.location.href)
    const params = new URLSearchParams(url.search.slice(1).replace(/&travel_event_id=(\d+$)/, ''))
    params.append("travel_event_id", resource_id)
    url.search = params.toString()

    return url.toString()
  }
}