import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['vehicleKind', 'labelRoad', 'labelWaterway', 'plate', 'document', 'chassisNum', 'city', 'modelYear', 'color', 'usage', 'category', 'shipName', 'assemblyField', 'assemblyRoad', 'assemblyWaterway']

  initialize() {
    this.defaultTargets = [this.labelRoadTarget, this.labelWaterwayTarget, this.plateTarget, this.documentTarget, this.chassisNumTarget, this.cityTarget, this.modelYearTarget, this.colorTarget, this.usageTarget, this.categoryTarget, this.shipNameTarget, this.assemblyFieldTarget, this.assemblyRoadTarget, this.assemblyWaterwayTarget]

    this.roadTargets = {
      enabled: [this.labelRoadTarget, this.plateTarget, this.documentTarget, this.chassisNumTarget, this.cityTarget, this.modelYearTarget, this.colorTarget, this.usageTarget, this.categoryTarget, this.assemblyFieldTarget, this.assemblyRoadTarget],
      disabled: [this.labelWaterwayTarget, this.shipNameTarget, this.assemblyWaterwayTarget]
    }

    this.waterwayTargets = {
      enabled: [this.labelWaterwayTarget, this.shipNameTarget, this.assemblyFieldTarget, this.assemblyWaterwayTarget],
      disabled: [this.labelRoadTarget, this.plateTarget, this.documentTarget, this.chassisNumTarget, this.cityTarget, this.modelYearTarget, this.colorTarget, this.usageTarget, this.categoryTarget, this.assemblyRoadTarget]
    }

    this.manipulateFields(this.vehicleKindTarget);
  }

  changeFields(event) {
    this.manipulateFields(event.target);
  }

  manipulateFields(vehicleKind) {
    this.hideElements(this.defaultTargets);

    const modal = vehicleKind.selectedOptions[0].getAttribute('data-modal');

    switch(modal) {
      case 'road':
        this.showElements(this.roadTargets.enabled);
        this.hideElements(this.roadTargets.disabled);
        break;
      case 'waterway':
        this.showElements(this.waterwayTargets.enabled);
        this.hideElements(this.waterwayTargets.disabled);
        break;
      default:
        this.hideElements(this.defaultTargets);
    }
  }

  showElements(elements){
    for (var element of elements) {
      element.classList.remove('hidden');
    }
  }

  hideElements(elements){
    for (var element of elements) {
      element.classList.add('hidden');
      this.disabledFields(element.children)
    }
  }

  disabledFields(input_list){
    for(const input of input_list){
      if(input instanceof HTMLInputElement) input.removeAttribute('required');
    }
  }
}