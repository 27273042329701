class ToggleBox extends HTMLElement {
  constructor() {
    super();
  }

  get checked() {
    return this.hasAttribute('checked');
  }

  set checked(value) {
    if (value) {
      this.setAttribute('checked', value);
    } else {
      this.removeAttribute('checked');
    }
    this.render();
    if (this.dataset.input) {
      document.getElementById(this.dataset.input).value = this.checked ? 'true' : 'false';
    }
  }

  render() {
    if (this.checked) {
      this.classList.add('checked');
    } else {
      this.classList.remove('checked');
    }
  }

  onToggle(event) {
    if (event instanceof KeyboardEvent && event.code.toLowerCase() !== 'space') {
      return;
    }
    this.checked = !this.checked;
  }

  connectedCallback() {
    this.addEventListener('click', this.onToggle.bind(this));
    this.addEventListener('keydown', this.onToggle.bind(this));
    if (!this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', 0);
    }
    this.checked = document.getElementById(this.dataset.input).value === 'true';
  }
}
customElements.define('toggle-box', ToggleBox);