import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "parent" , "child" ]

  connect() {
    if (this.childTargets.length >= 1) this.allChecked() ? this.parentTarget.checked = true : this.parentTarget.checked = false;
  }

  allChecked() {
    var check = 0;
    for (var i = 0; i < this.childTargets.length; i++) {
      if (this.childTargets[i].checked) {
        check++;
      }
    }
    if (check == this.childTargets.length) return true;
    return false
  }

  toggleChildren() {
    if (this.parentTarget.checked) {
      this.childTargets.map(x => x.checked = true)
    } else {
      this.childTargets.map(x => x.checked = false)
    }
  }

  toggleParent() {
    if (this.childTargets.map(x => x.checked).includes(false)) {
      this.parentTarget.checked = false
    } else {
      this.parentTarget.checked = true
    }
  }

  remove_checked(event) {
    this.parentTargets.map(x => {
      if(x === event.target) {
        return
      }
      if(x.checked === true) {
        x.checked = false
      }
    })
  }

  submit(event) {
    event.target.closest("form").requestSubmit();
  }
}
