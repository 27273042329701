import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = [ "select" , "fields" ]

  enable({ target }) {
    const selectedId = target.selectedOptions[0].id;
    
    this.hide_elements(selectedId)
    this.show_elements(selectedId)
  }

  connect(){
    const selectedId = this.selectTarget.selectedOptions[0].id
    this.hide_elements(selectedId)
  }
  
  hide_elements(id){
    for (const element of this.fieldsTargets){
      if (element.id != id) {
        element.classList.add("hidden") 
        this.disable_inputs(element.children)
      }
    }
  }

  disable_inputs(input_list){
    for(const input of input_list){
      if(input instanceof HTMLInputElement || input instanceof HTMLSelectElement) input.removeAttribute('required');
      if(input instanceof HTMLDivElement) this.disable_inputs(input.children);
    }
  }

  enable_inputs(input_list){
    for(const input of input_list){
      if(input instanceof HTMLInputElement || input instanceof HTMLSelectElement) input.setAttribute('required', 'required');
      if(input instanceof HTMLDivElement) this.enable_inputs(input.children);
    }
  }

  show_elements(id){
    for (const element of this.fieldsTargets){
      if (element.id == id){
        element.classList.remove("hidden")
        this.enable_inputs(element.children)
      }
    }
  }
}
