import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const event = new CustomEvent("purchaseOrderItemConnected", { 
      bubbles: true,
    })

    this.element.dispatchEvent(event)
  }
}
