import { Controller } from '@hotwired/stimulus';
import { get, FetchRequest } from "@rails/request.js";
// import { useHotkeys } from "stimulus-use";

export default class extends Controller {
  static targets = ['sidebar', 'select', 'drawer', 'main'];

  initialize() {
    const defaultTag = 'fleet';
    let menu_tag = this.handleTag(defaultTag);
    const request = new FetchRequest('get',`/menu?menu_tag=${menu_tag}`, { responseKind: "turbo-stream" })
    request.perform();

    const currentButton = this.element.querySelector(`[data-tag="${defaultTag}"]`);
    this.toggleButton(currentButton, menu_tag)
  }

  handleTag(tag) {
    if (tag) {
      this.saveInSession('$menu.tag', tag)
      return tag;
    }
    let savedTag = this.getFromSession('$menu.tag');

    if (savedTag) {
      return savedTag;
    }
    return null;
  }

  changeMenu(event) {
    const clickedButton = event.currentTarget;
    const tag = clickedButton.dataset.tag;
    let menu_tag = this.handleTag(tag);
    get(`/menu?menu_tag=${menu_tag}`, {
      responseKind: "turbo-stream"
    })

    this.toggleButton(clickedButton, tag)

    const toggleTrackingButton = this.element.querySelector(`[data-tag="tracking"]`);
    if(tag === 'config') {
      toggleTrackingButton.classList.add('hidden');
    } else {
      toggleTrackingButton.classList.remove('hidden');
    }

    this.expandSidebar();
  }

  toggleButton(clickedButton, tag) {
    clickedButton.classList.add('hidden')

    const otherTag = tag === 'fleet' ? 'config' : 'fleet';
    const otherButton = this.element.querySelector(`[data-tag="${otherTag}"]`);
    if (otherButton) {
      otherButton.classList.remove('hidden')
    }
  }

  onClick(event) {
    let item = event.target;
    if (item.classList.contains('menu-item')) {
      if (item.dataset['href'] == 'javascript:void(0);') {
        return;
      }
      this.saveInSession('$menu.selected', JSON.stringify({ group: item.dataset['groupId'] }));
      window.location = item.dataset['href'];
      return;
    }

    this.saveInSession('$menu.selected', JSON.stringify({ group: item.dataset['groupId'], item: item.dataset['id'] }))
  }

  open(event) {
    let item = event.target;
    if(item instanceof SVGElement){
      return;
    }

    if(!item.dataset['id']){
      let button = item.parentNode;
      this.showDropdown(button);
    } else {
      this.showDropdown(item);
    }
  }
  log(){
    console.log('test');
  }

  textDecoration(){
    let tag = this.getFromSession('$menu.selected')
    if (!tag) {
      return;
    }
    let selectedItem = JSON.parse(tag);

    let group = document.querySelector(`[data-group-id="${selectedItem.group}"]`)

    if (group) {
      this.showDropdown(group)
    }


    let item = document.querySelector(`[data-id="${selectedItem.item}"]`)

    if (item) {
      item.classList.add('underline')
    }
  }
  saveInSession(tag, value) {
    localStorage.setItem(tag, value);
  }

  getFromSession(tag) {
    return localStorage.getItem(tag);
  }

  showDropdown(button){
    let dropdown = document.getElementById(`dropdown-${button.dataset['id']}`)
    this.dropdown(dropdown)
    this.expandSidebar();
  }
  dropdown(dropdown){
    if(dropdown.classList.contains('hidden')){
      dropdown.classList.remove('hidden')
    } else {
      dropdown.classList.add('hidden')
    }
  }

  expandSidebar() {
    if(window.innerWidth >= 1024) {
      const sidebar = this.sidebarTarget;
      const main = this.mainTarget;

      sidebar.style.width = '288px';
      sidebar.querySelectorAll('.menu-text').forEach(element => {
        element.style.display = 'inline';
      });
      main.style.marginLeft = '288px';
    }
  }

  retractSidebar() {
    if(window.innerWidth >= 1024) {
      const sidebar = this.sidebarTarget;
      const main = this.mainTarget;

      sidebar.style.width = '90px';
      sidebar.querySelectorAll('.menu-text').forEach(element => {
        element.style.display = 'none';
      });
      main.style.marginLeft = '90px';

      const openDropdowns = sidebar.querySelectorAll('[id^="dropdown-"]');
      openDropdowns.forEach(dropdown => {
        dropdown.classList.add('hidden');
      });
    }
  }
}