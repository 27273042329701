import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["event"];
  static values = { url: String };

  show() {
    if (this.hasEventTarget) {
      this.eventTarget.classList.remove("hidden");
    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          this.element.append(fragment);
        });
    }
  }

  hide() {
    if (this.hasEventTarget) {
      this.eventTarget.classList.add("hidden");
    }
  }

  disconnect() {
    if (this.hasEventTarget) {
      this.eventTarget.remove();
    }
  }
}