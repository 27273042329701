import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["select", "text"]

  toggleSelect() {
    this.selectTarget.classList.toggle("hidden");
  }

  toggleText(event) {
    const textElement = event.currentTarget.querySelector('[data-external-devices-target="text"]');
    if (textElement) {
      textElement.classList.toggle("hidden");
    }
  }
}