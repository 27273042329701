import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "frame", "tasksFrame", "main", "workforce", "product", "workforceSubtotal", "productSubtotal", "total", "workforceValue", "productValue" ]

  initialize() {
    let resourceHiddenInput = document.querySelector('input[id="maintenance_document_resource_id"][type="hidden"]');
    let selectServiceTasksInput = document.getElementById('maintenance_document_service_task_ids');
    this.props = []

    resourceHiddenInput.addEventListener('input', () => {
      this.updateSrc(resourceHiddenInput.value);      
    });

    if (this.hasOptionsSelected(selectServiceTasksInput.children)) {
      this.selectOptions(selectServiceTasksInput.children)
    } 

    selectServiceTasksInput.addEventListener('change', () => {
      this.selectOptions(selectServiceTasksInput.children)
      this.updateSubtotal()
    })
  }

  workforceTargetConnected() {
    this.calculateWorkforceTotal();
    this.updateSubtotal();
  }

  workforceTargetDisconnected() {
    this.calculateWorkforceTotal();
    this.updateSubtotal();
  }

  productTargetConnected() {
    this.calculateProductTotal();
    this.updateSubtotal();
  }

  productTargetDisconnected() {
    this.calculateProductTotal();
    this.updateSubtotal();
  }

  updateSrc(resource_id) {
    this.frameTarget.setAttribute('src', this.buildURL(resource_id));
  } 

  buildURL(resource_id) {
    const url = new URL(this.frameTarget.src, window.location.href)
    const params = new URLSearchParams(url.search.slice(1).replace(/&resource_id=(\d+$)/, ''))
    params.append("resource_id", resource_id)
    url.search = params.toString()

    return url.toString()
  }

  hasOptionsSelected(element) {
    for (const option of element) {
      if (option.selected) return true
    }

    return false
  }

  selectOptions(options) {
    for (const option of options) {
      if (option.selected) {
        if (!this.props.includes(option.value)) {
          this.props.push(option.value);
          this.createFrame(parseInt(option.value));
        }
      } else {
        if (this.props.includes(option.value)) {
          this.props = this.props.filter(prop => prop !== option.value);
          this.removeFrame(parseInt(option.value));
        }
      }
    }

    this.tasksFrameTarget.classList.toggle('hidden', this.props.length !== 0);
  }

  createFrame(service_task_id) {
    let frame = document.createElement('turbo-frame');
    frame.setAttribute('src', this.buildTaskURL(service_task_id));
    frame.setAttribute('id', this.tasksFrameTarget.id);
    this.mainTarget.appendChild(frame);
  }

  removeFrame(service_task_id) {
    let frame = document.querySelector(`turbo-frame[src="${this.buildTaskURL(service_task_id)}"]`)
    frame.remove()
  }

  buildTaskURL(service_task_id) {
    const url = new URL(this.tasksFrameTarget.src, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append("service_task_id", service_task_id)
    url.search = params.toString()

    return url.toString()
  }

  updateSubtotal() {
    this.calculateWorkforceTotal()
    this.calculateProductTotal()
    this.calculateTotal()
  }

  calculateWorkforceTotal() {
    let total = this.workforceValueTargets.reduce((x, y) => {
      return x + parseFloat(y.getAttribute("data-maintenance-document-value")) 
    }, 0)
    this.workforceSubtotalTarget.textContent = total.toFixed(2)
    return total
  }
  
  calculateProductTotal() {
    let total = this.productValueTargets.reduce((x, y) => {
      return x + parseFloat(y.getAttribute("data-maintenance-document-value")) 
    }, 0)
    this.productSubtotalTarget.textContent = total.toFixed(2)
    return total
  }

  calculateTotal() {
    let workforceValue = this.calculateWorkforceTotal()
    let productValue = this.calculateProductTotal()
    let total = workforceValue + productValue

    this.totalTarget.textContent = total.toFixed(2)
  }
}

