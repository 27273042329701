import { Controller } from 'stimulus';
import printJS from 'print-js';

export default class extends Controller {
  static targets = [ "button", "print" ]
  static values = { title: String }

  connect() {
    printJS({
      printable: 'pdf',
      type: 'html',
      documentTitle: this.titleValue,
      maxWidth: 1920,
      gridStyle: 'border: 2px solid #3971A5;'
    })
  }
}
