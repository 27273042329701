import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['total', 'unit', 'amount', 'valueHelper']
  static values = { url: String }

  connect() {
    this.resourceHiddenField = document.querySelector('input[id="fuel_event_resource_id"][type="hidden"]');
    this.resourceHiddenField.addEventListener('change', this.refreshValueHelpers.bind(this))

    if (this.resourceHiddenField.value !== null) this.refreshValueHelpers()
  }

  refreshValueHelpers() {
    const resourceId = this.resourceHiddenField.value

    this.valueHelperTargets.forEach(valueHelper => {
      valueHelper.src = this.buildURL(resourceId)
    });
  }

  buildURL(id) {
    const url = new URL(this.urlValue);
    url.searchParams.set('resource_id', id);
    return url.toString();
  }

  calculate() {
    let unit = parseFloat(this.unitTarget.value);
    let amount = parseFloat(this.amountTarget.value);
    let total = parseFloat(this.totalTarget.value);

    if (this.amountTarget.value != "" && this.totalTarget.value != ""){
      this.unitTarget.value = (total / amount).toPrecision(4)
      return
    }
  }
}
