import { ApplicationController, useDebounce } from 'stimulus-use';
import { post } from "@rails/request.js";

export default class extends ApplicationController {
  static targets = ['filter', 'form'];
  static debounces = ['filter']

  connect() {
    useDebounce(this, { wait: 500 });
  }

  filter() {
    const url = new URL(this.formTarget.action)
    const searchParams = this.params(url.searchParams);
    post(`${url.pathname}?${searchParams.toString()}`,{
      responseKind: "turbo-stream"
    });
  }


  params(searchParams) {
    const params = new URLSearchParams();

    this.filterTargets.forEach((t) => {
      if (t.type === 'select-multiple') {
        let values = [...t.options].filter((x) => x.selected).map((x) => x.value);
        if(values.length){
          params.set(t.name, values.toString());
        }
      } else {
        if(t.value){
          params.set(t.name, t.value);
        }
      }
    });

    searchParams.forEach((value, key) => {
      params.append(key, value);

    });
    return params;
  }
}